<template>
  <vue-final-modal class="modal-free-spin-detail">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.bonusDetailsTitle') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('free-spin-detail')" />
      </div>
    </div>

    <div class="vfm__body">
      <card-free-spin :freeSpinInfo="props.freeSpinInfo" :gameInfo="props.gameInfo" hideButton />
      <div class="modal-free-spin-detail__subtitle" v-html="modalSubtitle" />

      <div v-if="props.freeSpinInfo.status === 4" class="modal-free-spin-detail__timer">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.availableTimerLabel') }}

        <bonuses-timer :expired-at="availableTimer as string" size="sm" />
      </div>

      <button-base
        v-else
        class="modal-free-spin-detail__button"
        type="primary"
        size="md"
        :isDisabled="processingBonuses.includes(props.freeSpinInfo.id)"
        @click="clickAction"
      >
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.freeSpin.playButton') }}
      </button-base>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIFreeSpinDetailsModal, IGame, IPlayerFreeSpin } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIFreeSpinDetailsModal>;
    defaultLocaleData: Maybe<CIFreeSpinDetailsModal>;
    freeSpinInfo: IPlayerFreeSpin;
    gameInfo: IGame;
  }>();

  const { getContent, replaceContent, localizePath } = useProjectMethods();
  const { closeModal } = useModalStore();
  const bonusStore = useBonusStore();
  const { processingBonuses } = storeToRefs(bonusStore);
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent, alertsData, defaultLocaleAlertsData } =
    useGlobalStore();
  const { showAlert } = useLayoutStore();

  const modalSubtitle = computed(() => {
    const subtitleContent = getContent(props.currentLocaleData, props.defaultLocaleData, 'subtitle') || '';

    return replaceContent(subtitleContent, '*');
  });

  const availableTimer = computed(() => {
    if (props.freeSpinInfo.status === 4 && props.freeSpinInfo.issuedAt) return props.freeSpinInfo.issuedAt;
    return undefined;
  });

  const router = useRouter();
  const { activatePlayerFreeSpin } = useCoreBonusApi();
  const clickAction = async (): Promise<void> => {
    if (props.freeSpinInfo.status === 1) {
      if (processingBonuses.value.includes(props.freeSpinInfo.id)) return;
      processingBonuses.value.push(props.freeSpinInfo.id);

      try {
        await activatePlayerFreeSpin(props.freeSpinInfo.id);
        processingBonuses.value = processingBonuses.value.filter(id => id !== props.freeSpinInfo.id);
        await router.push(localizePath(`/games/${props.gameInfo?.identity}?real=true`));
        await closeModal('free-spin-detail');
      } catch {
        processingBonuses.value = processingBonuses.value.filter(id => id !== props.freeSpinInfo.id);
        showAlert(alertsData?.global?.somethingWrong || defaultLocaleAlertsData?.global?.somethingWrong);
      }
    } else {
      await router.push(localizePath(`/games/${props.gameInfo?.identity}?real=true`));
      await closeModal('free-spin-detail');
    }
  };
</script>

<style src="~/assets/styles/components/modal/free-spin-detail.scss" lang="scss" />
